<template>
    <div class="session-bg">
        <div class="signup4-container">
            <base-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                ></v-progress-linear>
                <v-row no-gutters>
                    <v-col cols="12" md="4" class="pa-10 signup-img-container">
                        <center>
                            <img
                                class=""
                                src="@/assets/images/illustrations/posting_photo.svg"
                            />
                            <v-col cols="12" class="py-10">
                                <p>Already have an account?</p>
                                <v-btn
                                    text
                                    color="primary"
                                    to="/app/sessions/sign-in"
                                    >Sign In</v-btn
                                >
                            </v-col>
                            <div v-if="error != null">
                                <v-alert dense border="left" type="warning">
                                    {{ error }}
                                </v-alert>
                            </div>
                        </center>
                    </v-col>
                    <v-col cols="12" md="8" class="bg-gray-300">
                        <div>
                            <v-stepper v-model="e1">
                                <v-stepper-header>
                                <v-stepper-step
                                    :complete="e1 > 1"
                                    step="1"
                                >
                                    Business Info
                                </v-stepper-step>

                                <v-divider />

                                <v-stepper-step
                                    :complete="e1 > 2"
                                    step="2"
                                >
                                    Business Address
                                </v-stepper-step>

                                <v-divider />

                                <v-stepper-step step="3">
                                    User Details
                                </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-form ref="businessInfoform" v-model="valid1" validation>
                                        <div class="pa-4">
                                            <v-row>
                                                <v-col cols="12">
                                                <v-text-field required :rules="nameRules" dense v-model="formData.organization.name" label="Name" />
                                                </v-col>
                                                <v-col cols="12">
                                                <v-textarea
                                                    dense
                                                    required
                                                    :rules="[(v) => !!v || 'Description is required']"
                                                    v-model="formData.organization.description"
                                                    :rows="2"
                                                    :row-height="20"
                                                    name="input-7-4"
                                                    label="Description"
                                                />
                                                </v-col>
                                                <v-col class="d-flex" cols="12" md="6">
                                                    <v-select :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.orgTypeId"  dense :items="getOrganisationTypes" item-text="name" item-value="orgTypeId" label="Organisation Type" />
                                                </v-col>
                                                <v-col class="d-flex" cols="12" md="6">
                                                    <v-select 
                                                        :rules="[(v) => !!v || 'Item is required']"
                                                        required
                                                        v-model="formData.organization.businessTypeId"
                                                        dense :items="getBusinessTypes"
                                                        item-text="name"
                                                        item-value="businessTypeId"
                                                        label="Business Type" />
                                                </v-col>
                                                <v-col class="d-flex" cols="12" md="6">
                                                    <v-select :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.countryId" dense :items="getCountryList" item-text="name" item-value="countryId"  label="Country" />
                                                </v-col>
                                                <v-col class="d-flex" cols="12" md="6">
                                                    <v-select :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.currencyId" dense :items="getCurrencyList" item-text="isoCode" item-value="currencyId"  label="Currency" />
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="d-flex float-right">
                                            <v-btn
                                            :disabled="!valid1"
                                            color="primary"
                                            @click="nextStep(1)"
                                            >
                                            Continue
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <v-form ref="form2" v-model="valid2" validation>
                                        <div class="pa-4">
                                            <v-row>
                                                <v-col cols="12" md="5">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.city" dense label="City" />
                                                </v-col>
                                                <v-col cols="12" md="7">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.postalAddress" dense label="Postal Address" />
                                                </v-col>
                                                <v-col cols="12">
                                                <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.addressLineOne" dense label="Address Line 1" />
                                                </v-col>
                                                <v-col cols="12">
                                                <v-text-field v-model="formData.organization.addressLineTwo" dense label="Address Line 2" />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.organization.phone" dense label="Phone" />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field :rules="emailRules" required v-model="formData.organization.email" label="Email" dense />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="formData.organization.website" label="Website" dense />
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="d-flex float-right">
                                            <v-btn text @click="e1 -= 1">
                                                Back
                                            </v-btn>
                                            <v-btn
                                            color="primary"
                                            :disabled="!valid2"
                                            @click="nextStep(2)"
                                            >
                                                Continue
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </v-stepper-content>

                                <v-stepper-content step="3">
                                    <v-form ref="form3" v-model="valid3" validation>
                                        <div class="pa-4 mt-3">
                                            <v-row>
                                                
                                                <v-col cols="12" md="12">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.username" dense label="Username" />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.firstName" dense label="Firstname" />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.lastName" label="Lastname" dense />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field :rules="[(v) => !!v || 'Item is required']" required v-model="formData.phoneNo" dense label="Phone" />
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field :rules="emailRules" required v-model="formData.email" label="Email" dense />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        name="input-10-2"
                                                        label="Password"
                                                        :rules="[(v) => !!v || 'Item is required']"
                                                        required
                                                        v-model="formData.password"
                                                        @click:append="show = !show"
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        name="input-10-2"
                                                        label="Confirm Password"
                                                        :rules="[(v) => !!v || 'Item is required']"
                                                        required
                                                        v-model="passwordConfirm"
                                                        @click:append="show = !show"
                                                    ></v-text-field>
                                                </v-col>
                                                
                                                <v-checkbox
                                                    v-model="checkbox1"
                                                    label="I agree with terms and conditions."
                                                ></v-checkbox>
                                            </v-row>

                                        </div>

                                        <div v-if="!loading" class="d-flex float-right">

                                            <v-btn text @click="e1 -= 1">
                                                Back
                                            </v-btn>
                                            <v-btn
                                            color="primary"
                                            :disabled="!valid3"
                                            @click="nextStep(3)"
                                            >
                                            Continue
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                        
                    </v-col>
                </v-row>
            </base-card>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'SignUp',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignUp'
    },
    data() {
        return {
            e1: 1,
            steps: 3,
            valid1: false,
            valid2: false,
            valid3: false,
            show: false,
            passwordConfirm: '',
            checkbox1: true,
            formData: {
                organization: {
                    "name": "",
                    "description": "",
                    "clientId": 1,
                    "businessTypeId": 0,
                    "orgTypeId": 0,
                    "countryId": 0,
                    "currencyId": 0,
                    "city": "",
                    "addressLineOne": "",
                    "addressLineTwo": "",
                    "postalAddress": "",
                    "email": "",
                    "phone": "",
                    "website": "",
                    "logoUrl": "",
                },
                "username": "",
                "email": "",
                "firstName": "",
                "lastName": "",
                "password": "",
                "phoneNo": "",
                "logoUrl": "",
                "roles": []
            },
            nameRules: [
                (v) => !!v || 'Required',
                // (v) => v.length <= 10 || 'Name must be less than 10 characters'
            ],
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
        }
    },
    mounted () {
        this.fetchOrganisationTypes()
        this.fetchBusinessTypes()
        this.fetchCountries()
        this.fetchCurrencies()
    },
    computed: {
        ...mapGetters(['getOrganisationTypes', 'signUpSuccess', 'getBusinessTypes', 'getCountryList', 'getCurrencyList','loading','error'])
    },
    watch: {
        steps (val) {
            if (this.e1 > val) {
            this.e1 = val
            }
        },
        signUpSuccess (val) {
            if (val) {
                // this.makeToast("success", "Successfully Logged In");
                console.log('signed up successfully ')
                
                setTimeout(() => {
                    this.$router.push('/app/sessions/sign-in')
                }, 100)
            }
        },
    },

    methods: {
        ...mapActions(['fetchOrganisationTypes', 'fetchBusinessTypes', 'fetchCountries', 'fetchCurrencies', 'signUserUp']),
      nextStep (n) {
        if (n === this.steps) {
          this.signUserUp(this.formData)
        } else {
          this.e1 = n + 1
        }
      },
    },
}
</script>
<style lang="scss">
.session-bg {
    background-image: url('../../../assets/images/waterfall.jpg');
    min-height: 100vh;
    align-items: center;
    place-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}
.signup-img-container {
    height: 100%;
    align-items: center;
    place-content: center;
}
.signup4-header img {
    width: 200px;
}
.signup4-container img {
    max-height: 300px;
    max-width: 100%;
}
@media only screen and (min-width: 1024px) {
    .signup4-container {
        margin: 42px;
        max-width: 900px;
    }
}
</style>
